import { auth, googleAuthProvider, db, serverTimestamp } from '../lib/firebase/firebase.js';
import {  requiredFieldValidator, alphabetTextWithSpaceValidator, phoneNumberValidator, validate } from '../lib/validator.js';
import { authorizedRouteStudent } from '../lib/auth.js';
import Swal from 'sweetalert2';


let loginForm = document.getElementById("student-login");

loginForm.addEventListener("click", async (e) => {
    e.preventDefault();

    let loginButton = document.getElementById("student-login");
    // loginButton.textContent = "Signing in...";
    loginButton.disabled = true;
    try {
        let user = await auth.signInWithPopup(googleAuthProvider);

        let student = await db.collection("students").doc(user.user.uid).get();
        // console.log(student.data().profile_updated)
        if (student.exists) {

            if (student.data().profile_updated) {

                await Swal.fire({
                    icon: 'success',
                    title: 'Logged In',
                    text: 'You will be redirected shortly',
                    allowOutsideClick: false
                });
                sessionStorage.loggedIn = "true";
                window.location.href = authorizedRouteStudent;
            }
            else {

                $('#profile_modal').modal('toggle');

            }

        }
        else {


            let data = {
                "student_id": user.user.uid,
                "name": null,
                "dob": null,
                "gender": null,
                "looking_for": null,
                "phone_number": null,
                "district": null,
                "profile_updated": false,
                "email": user.user.email,
                "registered_on": serverTimestamp(),
                "higher_secondary_stream": null,
                "ug_stream": null,
            };

            db.collection("students").doc(user.user.uid).set(data);

            $('#profile_modal').modal('toggle');
           

        }

        // loginButton.textContent = "Sign In";
        loginButton.disabled = false;
    }
    catch (error) {
        await Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            text: 'Please Check Your Connection',
        });

        console.log(error.message);

        // loginButton.textContent = "Sign In";
        loginButton.disabled = false;

    }

});

let districtSelect = document.getElementById("district");

db.collection("states").where("state", "==", "kerala").get().then((state) => {
    let districts = state.docs[0].data().districts;

    districts.forEach((district) => {
        let option = document.createElement("option");
        option.value = district;
        option.textContent = district;

        districtSelect.append(option);
    })
})

let higherSecondaryStreams = [
    "Science / Maths",
    "Information Technology (IT) / Computer Applications",
    "Commerce / Management",
    "Humanities / Arts / History",
    "Others",
];

let ugStreams = [
    "Science",
    "Engineering",
    "Commerce",
    "Arts",
    "Management",
    "Computer Applications/IT",
    "Hotel Management",
    "Others"
];

let lookingForSelect = document.getElementById("looking_for");

lookingForSelect.addEventListener("change", (e) => {
    let lookingFor = e.target.value;
    let previousStreamSelect = document.getElementById("previous_stream");
    previousStreamSelect.innerHTML = "";

    if (lookingFor == "ug") {
        higherSecondaryStreams.forEach((stream) => {
            let option = document.createElement("option");
            option.textContent = stream;
            option.value = stream;
            previousStreamSelect.append(option);
        })
    }
    else if (lookingFor == "pg") {
        ugStreams.forEach((stream) => {
            let option = document.createElement("option");
            option.textContent = stream;
            option.value = stream;
            previousStreamSelect.append(option);
        })
    }
})

let previousStreamSelect = document.getElementById("previous_stream");
previousStreamSelect.innerHTML = "";

let lookingForCurrent = lookingForSelect.value;

if (lookingForCurrent == "ug") {
    higherSecondaryStreams.forEach((stream) => {
        let option = document.createElement("option");
        option.textContent = stream;
        option.value = stream;
        previousStreamSelect.append(option);
    })
}
else if (lookingForCurrent == "pg") {
    ugStreams.forEach((stream) => {
        let option = document.createElement("option");
        option.textContent = stream;
        option.value = stream;
        previousStreamSelect.append(option);
    })
}


let profileUpdateForm = document.getElementById("profile_form");

profileUpdateForm.addEventListener("submit", async (e) => {
    e.preventDefault();


    let submitButton = profileUpdateForm.querySelector("button");

    let name = document.getElementById("name");
    let dob = document.getElementById("dob");
    let phoneNumber = document.getElementById("phone_number");
    let gender = document.getElementById("gender");
    let lookingFor = document.getElementById("looking_for");
    let district = document.getElementById("district");
    let previousStream = document.getElementById("previous_stream");

    let submitText = "Update";
    let submittingText = "Updating...";

    submitButton.textContent = submitText;


    let formElements = [
        {
            field: name,
            validator: alphabetTextWithSpaceValidator,
            error_label: "name_error_label"
        },
        {
            field: dob,
            validator: requiredFieldValidator,
            error_label: "dob_error_label"
        },

        {
            field: phoneNumber,
            validator: phoneNumberValidator,
            error_label: "phone_number_error_label"
        },

        {
            field: gender,
            validator: requiredFieldValidator,
            error_label: "gender_error_label"
        },

        {
            field: lookingFor,
            validator: requiredFieldValidator,
            error_label: "looking_for_error_label"
        },
        {
            field: district,
            validator: requiredFieldValidator,
            error_label: "district_error_label"
        },
        {
            field: previousStream,
            validator: requiredFieldValidator,
            error_label: "previous_stream_error_label"
        },






    ];


    if (validate(formElements)) {

        submitButton.textContent = submittingText;
        submitButton.disabled = true;

        try {

            let data = {
               name: name.value.toLowerCase().trim(),
               dob: new Date(dob.value.toLowerCase().trim()),
               phone_number: phoneNumber.value.toLowerCase().trim(),
               gender: gender.value.toLowerCase().trim(),
               looking_for: lookingFor.value.toLowerCase().trim(),
               district: district.value.toLowerCase().trim(),
               profile_updated: true
            };

            if(lookingFor.value == "ug"){
                data["higher_secondary_stream"] = previousStream.value.toLowerCase().trim();
            }
            else if(lookingFor.value == "pg"){
                data["ug_stream"] = previousStream.value.toLowerCase().trim();
            }

            let user = auth.currentUser;

            if(user != null){
                await db.collection("students").doc(user.uid).update(data);

                await Swal.fire({
                    icon: 'success',
                    title: 'Signed In',
                    text: 'You will be redirected shortly',
                    allowOutsideClick: false
                });

                window.location.href = "student/colleges.html";
            }
            else{
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something Went Wrong',
                });
                
            }

            submitButton.textContent = submitText;
            submitButton.disabled = false;
            $('#profile_modal').modal('toggle');

        }
        catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something Went Wrong',
            });

            submitButton.textContent = submitText;
            submitButton.disabled = false;
            //TODO Delete Console Log
            console.log(error.message);
            $('#profile_modal').modal('toggle');
           

        }

    }
})


auth.onAuthStateChanged(async (user) => {
    let googleSignInButton = document.getElementById("student-login");
    // googleSignInButton.hidden = false;
    
    if(user){

        let student = await db.collection("students").doc(user.uid).get();

        if(student.exists){
            // googleSignInButton.hidden = true;
            if(student.data().profile_updated){
                window.location.href = "student/colleges.html";
            }
            else{
                $('#profile_modal').modal('toggle');
            }
        }
       
    }
})