/**
 * A validator function that accepts a list of json objects and checks for invalid fields
 * @param  {Object} formElements A list of json objects which must contain the fields 'field' (The HTML form element reference) and 'validator'(A validator function)
 * @return {Boolean}      Returns true if all fields are valid, else returns false
 */

// TODO Check For Error Case When Only One Field Is Filled
export const validate = (formElements) => {
    let validForm = true;

    formElements.forEach((element) => {

        let error_label = document.createElement("span");
        error_label.classList.add("text-danger");
        error_label.id = element.error_label;

        let formElement = element.field;
        let notValid;

        let isFileElement = formElement.tagName == "INPUT" && formElement.type == "file";
        
        notValid = isFileElement ?  element.validator(formElement.value.trim(), formElement.files[0]): element.validator(formElement.value.trim());

        if (notValid) {
            error_label.textContent = notValid;
            if(formElement.getAttribute("error-label-added") !== 'true'){
                formElement.after(error_label);
                formElement.setAttribute("error-label-added", 'true');
            }
            validForm = false;
        }
        
        if (formElement.getAttribute("on-change-listener") !== 'true') {
            let listener = isFileElement ? fileEventListener : inputEventListener;
            formElement.addEventListener("change", e => listener(e, element));
            formElement.setAttribute("on-change-listener", 'true');
        }

    });

    return validForm;
}

const inputEventListener = (e, element) => {
    let notValid = element.validator(e.srcElement.value.trim());

    let error_label = document.getElementById(element.error_label);
    if(error_label){
        if (notValid) {
            error_label.textContent = notValid;
    
        }
        else {
            error_label.textContent = "";
        }
    }
};

const fileEventListener = (e, element) => {
    let notValid = element.validator(e.srcElement.value.trim(), e.srcElement.files[0]);
    let error_label = document.getElementById(element.error_label);
    if(error_label){
        if (notValid) {
            error_label.textContent = notValid;
    
        }
        else {
            error_label.textContent = "";
        }
    }
    
};

export const emailValidator = (value) => {

    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value == "") {
        return "Enter Email";
    }
    else if (regex.test(value) == false) {
        return "Invalid Email";
    }

    return false;
}

export const passwordValidator = (value) => {
    if (value == "") {
        return "Enter Password";
    }
    else if(value.length < 6){
        return "Password must be 6 characters at least";
    }

    return false;
}

export const requiredFieldValidator = (value) => {
    if (value == "") {
        return "Required";
    }

    return false;
}

export const alphabetTextWithSpaceValidator = (value) => {
    let regex = /^[a-zA-Z ]*$/;

    if (value == "") {
        return "Field Required";
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry";
    }

    return false;
}

export const numberValidator = (value) => {
    let regex = /^[0-9]*$/;
    let num = parseInt(value);

    if (value == "") {
        return "Field Required";
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry! Enter Number";
    }
    else if (num < 0 ) {
        return "Invalid Entry!";
    }
    

    return false;
}

export const imageValidator = (value, file) => {
    let regex = /(\.jpg|\.jpeg|\.png|\.svg)$/i;

    if (!regex.exec(value)) { 
        return "Please Enter An Image"; 
    }  

    return false;
}

export const svgValidator = (value, file) => {
    let regex = /(\.svg)$/i;

    if (!regex.exec(value)) { 
        return "Please Enter An SVG File"; 
    }  

    return false;
}

export const pngValidator = (value, file) => {
    let regex = /(\.png)$/i;

    if (!regex.exec(value)) { 
        return "Please Enter A PNG File"; 
    }

    return false;
}

export const pngUpdateValidator = (value, file) => {
    let regex = /(\.png)$/i;

    if(value == ""){
        return false;
    }
    if (!regex.exec(value)) { 
        return "Please Enter A PNG File"; 
    }

    return false;
}

export const phoneNumberValidator = (value) => {
    let regex = /^[0-9]{10}$/;
    let num = parseInt(value);

    if (value == "") {
        return "Field Required";
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry! Enter 10 Digit Number";
    }
    

    return false;
}

export const urlValidator = (value, file) => {
    let regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi; 

    if(value == ""){
        return "Field Required"
    }
    else if (!regex.exec(value)) { 
        return "Invalid URL"; 
    }  

    return false;
}

export const pinValidator = (value) => {
    let regex = /^[0-9]{6}$/;
    let num = parseInt(value);

    if (value == "") {
        return "Field Required";
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry! Enter 6 Digit Number";
    }
    

    return false;
}

export const urlOptionalValidator = (value, file) => {
    let regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi; 

    if(value == ""){
        return false;
    }
    else if (!regex.exec(value)) { 
        return "Invalid URL"; 
    }  

    return false;
}



export const phoneNumberOptionalValidator = (value) => {
    let regex = /^[0-9]{10}$/;
    let num = parseInt(value);

    if (value == "") {
        return false;
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry! Enter 10 Digit Number";
    }
    

    return false;
}


export const alphabetTextWithSpaceOptionalValidator = (value) => {
    let regex = /^[a-zA-Z ]*$/;

    if (value == "") {
        return false;
    }
    else if (regex.test(value) == false) {
        return "Invalid Entry";
    }

    return false;
}


export const pdfValidator = (value, file) => {
    let regex = /(\.pdf)$/i;

    if (!regex.exec(value)) { 
        return "Please Enter A PDF File"; 
    }  

    return false;
}
